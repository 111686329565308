import { TranslationsContext } from '@teliaee/telia-translate';
import { observer } from 'mobx-react';
import { CardPaymentReturn, Payment } from 'payment-react-component';
import * as React from 'react';
import { Button, Grid, GridColumn } from 'telia-front-react';

import config from '../config';
import { useStores } from '../stores/RootStore';

interface IPaymentMethodProps {
  disabled: boolean;
}

const PaymentMethods = observer((props: IPaymentMethodProps) => {
  const { paymentMethodsStore, posPaymentStore, sessionStore } = useStores();
  const translate = React.useContext(TranslationsContext).translate;

  const renderPaymentMethods = (): JSX.Element => {
    return <>{sessionStore.isDealer ? renderDealerPaymentMethod() : renderClientPaymentMethods()}</>;
  };

  const renderClientPaymentMethods = (): JSX.Element => {
    const store = paymentMethodsStore;
    return (
      <Payment
        referenceNumber={store.referenceNumber}
        environment={config.frontendEnvironmentName}
        source={store.paymentSource}
        language={store.language}
        initialAmount={store.paymentAmount}
        brand={store.brand}
        paymentMethods={store.paymentMethods}
        banklinkPayment={store.bankLinkPaymentProps}
        cardPayment={{ redirectFrameTarget: '_self', returnUrl: paymentMethodsStore.returnUrl || window.location.href, shopperReference: null }}
        headless={true}
        paymentOrderParams={store.paymentOrderParams}
      />
    );
  };

  const renderDealerPaymentMethod = (): JSX.Element => {
    return (
      <Grid className="grid--prepaid" align={['middle-xs']}>
        <GridColumn width={['md-9', 'lg-7', 'xl-6']}>
          <Button type="employee" text={translate('prepaid.step.pos-payment-button')} onClick={posPaymentStore.createPayment} />
        </GridColumn>
      </Grid>
    );
  };

  if (paymentMethodsStore.transactionReference) {
    return (
      <CardPaymentReturn
        cardTransactionReference={paymentMethodsStore.transactionReference}
        finalizePaymentProps={{
          onFinalStatus: paymentMethodsStore.goToPaymentStatus
        }}
        environment={config.frontendEnvironmentName}
        brand={paymentMethodsStore.brand}
        language={paymentMethodsStore.language}
      />
    );
  }
  return <>{!props.disabled && renderPaymentMethods()}</>;
});

export default PaymentMethods;
